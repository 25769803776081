import { FeatureData } from '@innedit/innedit';
import { ChannelType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../components/View';
import Form from '../../../../containers/Espace/Form';
import params from '../../../../params/canal/creation.json';
import TemplateEspace from '../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../utils/requireEspace';
import { UserProps } from '../../../../utils/requireUser';

const PagePagesCreate: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { espaceId },
  } = props;

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          {...props}
          itemPathnamePrefix={`/espaces/${espaceId}/canaux/`}
          itemPathnameSuffix="apercu"
          model={
            new FeatureData<ChannelType>({
              espaceId,
              params,
              collectionName: 'channels',
            })
          }
          name="channel"
          type="create"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PagePagesCreate);
